import { userActions } from "../actionTypes";

export const fetchUsersInitiate = (data) => {
  return {
    type: userActions.FETCH_USERS_INITIATE,
    payload: data,
  };
};

export const fetchUsersSuccess = (data) => {
  return {
    type: userActions.FETCH_USERS_SUCCESS,
    payload: data,
  };
};

export const toggleActivationInitiate = (data) => {
  return {
    type: userActions.SET_USER_INITIATE,
    payload: data,
  };
};

export const toggleActivationSuccess = (data) => {
  return {
    type: userActions.SET_USER_SUCCESS,
    payload: data,
  };
};

export const userApprovalInitiate = (data) => {
  return {
    type: userActions.SET_USER_APPROVAL_INITIATE,
    payload: data,
  };
};

export const userApprovalSuccess = (data) => {
  return {
    type: userActions.SET_USER_APPROVAL_SUCCESS,
    payload: data,
  };
};

export const deleteUserInitiate = (data) => {
  return {
    type: userActions.DELETE_USER_INITIATE,
    payload: data,
  };
};

export const deleteUserSuccess = (data) => {  
  return {
    type: userActions.DELETE_USER_SUCCESS,
    payload: data,
  };
};

