import { put } from "redux-saga/effects";
import {
  editSettingsSuccess,
  fetchSettingsSuccess,
  setUserSettingsSuccess,
  setUserSettingsFail,
  fetchSettingsFail,
  editSettingsFail,
} from "../actionCreators/settingsActions";
import { makereq, toaster } from "../../utils/utils";
var qs = require("qs");

export function* fetchSettingsSaga({ payload }) {
  try {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const res = yield payload && makereq(`api/settings`, "get", null, headers);
    let data = res.data;
    yield put(fetchSettingsSuccess({ data }));
  } catch (error) {
    yield put(fetchSettingsFail());
  }
}

export function* editSettingsSaga({ payload }) {
  try {
    const form = qs.stringify({
      password: payload.password,
      smtpemail: payload.smtpemail,
      smtppassword: payload.smtppassword,
      smtpHost: payload.smtpHost,
      smtpPort: payload.smtpPort,
      commission: payload.commission,
      addFundCommission: payload.addFundCommission,
      withdrawFundCommission: payload.withdrawFundCommission,
      adminRelationshipId: payload.adminRelationshipId,
      adminAccountId: payload.adminAccountId,
      iosAppVersion: payload.iosAppVersion,
      iosAppUpdateForceFully:payload.iosAppUpdateForceFully,
      androidAppVersion:payload.androidAppVersion,
      androidAppUpdateForceFully:payload.androidAppUpdateForceFully,
    });
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const res = yield makereq(
      `api/settings/${payload.id}`,
      "put",
      form,
      headers
    );
    const { data } = res;
    yield put(editSettingsSuccess({ data }));
    toaster("success", "Settings Updated");
  } catch (error) {
    toaster("error", error);
    yield put(editSettingsFail());
  }
}

export function* setUserSettingsSaga({ payload }) {
  try {
    const form = qs.stringify({
      password: payload.password,
      smtpemail: payload.smtpemail,
      smtppassword: payload.smtppassword,
      smtpHost: payload.smtpHost,
      smtpPort: payload.smtpPort,
      commission: payload.commission,
      addFundCommission: payload.addFundCommission,
      withdrawFundCommission: payload.withdrawFundCommission,
      adminRelationshipId: payload.adminRelationshipId,
      adminAccountId: payload.adminAccountId,
      iosAppVersion: payload.iosAppVersion,
      iosAppUpdateForceFully:payload.iosAppUpdateForceFully,
      androidAppVersion:payload.androidAppVersion,
      androidAppUpdateForceFully:payload.androidAppUpdateForceFully,
    });
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const res = yield makereq(
      `api/settings/addSettings`,
      "post",
      form,
      headers
    );
    const { data } = res;
    yield put(setUserSettingsSuccess(data));
    toaster("success", "Settings Updated");
  } catch (error) {
    toaster("error", error);
    yield put(setUserSettingsFail());
  }
}
