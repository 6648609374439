import axios from "axios";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { toaster } from "./utils";
import { logout } from "../redux/actionCreators/authenticateActions";
import history from "../history";
import Storage from "./storage";

const _ = { get, isEmpty };
const baseURL = process.env.REACT_APP_BASE_URL;
const instance = axios.create({
  baseURL: baseURL,
  //   timeout: process.env.REACT_APP_AXIOS_MIN_TIMEOUT,
});

const token = Storage.loadToken();

if (token) {
  instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  //   instance.defaults.timeout = process.env.REACT_APP_AXIOS_MAX_TIMEOUT;
}

instance.defaults.headers.post["Content-Type"] = "application/json";

instance.interceptors.response.use(
  (response) => {
    let newToken = _.get(response, "data.token", "");
    if (!_.isEmpty(newToken)) {
      instance.defaults.headers.common["Authorization"] = `Bearer ${newToken}`;
    }
    let status = _.get(response, "status", false);
    let message = _.get(
      response,
      "message",
      "Something went wrong, please try again later"
    );

    if (!status) {
      logout();
      history.replace("/sign-in");
      toaster("error", message);
    }
    if (status === 200) return response;
  },
  (error) => {
    return error;
  }
);

export default instance;
