import React, { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  componentDidCatch(error, info) {
    this.setState(error);
  }

  render() {
    if (this.state.error) {
      return <div>Something went wrong, please refresh the page!</div>;
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
