import {
  handleApiResponseErrors,
  handleApiResponseSuccess,
  makereq,
  toaster,
} from "../utils/utils";

export const getStaticContent = async (metakeyword) => {
  try {
    const response = await makereq(`api/static-pages/${metakeyword}`, "get");
    return response;
  } catch (error) {}
};

export const fetchDetails = async (id) => {
  try {
    const response = await makereq(`api/auth/getProfileById/${id}`, "get");
    const message = response?.data?.message;
    handleApiResponseSuccess(message);
    return response.data;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

export const activeUserDetails = async (id) => {
  try {
    const response = await makereq(`api/auth/accountInfo/${id}`, "get");
    const message = response?.data?.message;
    handleApiResponseSuccess(message);
    return response.data;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

export const fetchAllShares = async () => {
  try {
    const response = await makereq(`api/shares/fetchAllShare`, "get");
    return response.data;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

export const createCategory = async (category) => {
  const formData = new FormData();
  if (
    category.categoryName &&
    category.categoryImage &&
    category.categoryImage.name
  ) {
    formData.append("categoryName", category.categoryName);
    formData.append(
      "categoryImage",
      category.categoryImage,
      category.categoryImage.name
    );
  } else {
    toaster("error", "Fill details completely");
    return false;
  }

  try {
    const response = await makereq(`api/category`, "post", formData);
    const message = response?.message;
    handleApiResponseSuccess(message);
    return response;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

export const addShareToCategory = async (category) => {
  let categoryid = category.category;
  let assetid = category.assetid;

  try {
    const response = await makereq(
      `api/category/${categoryid}/${assetid}`,
      "post"
    );
    const message = response?.message;
    handleApiResponseSuccess(message);
    return response;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

export const manageCategory = async () => {
  try {
    const response = await makereq("api/category", "get");
    return response && response?.data && response.data;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

export const deleteCategory = async (id) => {
  try {
    const response = await makereq(`api/category/${id}`, "delete");
    const message = response?.message;
    handleApiResponseSuccess(message);
    return response;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

export const editCategory = async (category, id) => {
  const formData = new FormData();
  if (category.categoryName) {
    formData.append("categoryName", category.categoryName);
  }
  if (category.categoryImage) {
    formData.append(
      "categoryImage",
      category.categoryImage,
      category.categoryImage.name
    );
  }
  try {
    const response = await makereq(`api/category/${id}`, "put", formData);
    response.message
      ? toaster("success", response.message)
      : toaster("success", "request succesufull");
    return response;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

// assset manage api
export const manageShare = async (categoryID) => {
  try {
    const response = await makereq(`api/category/${categoryID}`, "get");
    return response.data;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

export const getCategoryById = async (id) => {
  try {
    const response = await makereq(`api/category/categoryById/${id}`, "get");
    return response.data;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

export const deleteAsset_category = async (asset_id, category_id) => {
  try {
    const response = await makereq(
      `api/category/${category_id}/${asset_id}`,
      "delete"
    );
    const message = response?.message;
    handleApiResponseSuccess(message);
    return response.data;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

//  register on third party
export const registerOnThirdParty = async (userData) => {
  try {
    const response = await makereq(`api/auth/adminThirdData`, "post", userData);
    const message = response?.data?.message;
    handleApiResponseSuccess(message);
    return response;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

// reject a user

export const rejectUserApi = async (message, Id) => {
  try {
    const response = await makereq(
      `api/auth/admin/rejectUser/${Id}`,
      "put",
      message
    );
    const message = response?.data?.message;
    handleApiResponseSuccess(message);
    return response;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

// get all country
export const fetchCountries = async () => {
  try {
    const response = await makereq(`api/auth/nationandNationality`, "get");
    return response.data;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

export const fetchStates = async (countryCode) => {
  try {
    const response = await makereq(
      `api/auth/admin/states/${countryCode}`,
      "get"
    );
    return response.data;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

export const fetchCity = async (stateCode, countryCode) => {
  try {
    const response = await makereq(
      `api/auth/admin/cities/${countryCode}/${stateCode}`,
      "get"
    );

    return response.data;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

// most bought api
export const mostBoughtList = async () => {
  try {
    const response = await makereq(`api/mb`, "get");
    return response.data;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

export const deleteAssetMostBought = async (id) => {
  try {
    const response = await makereq(`api/mb/${id}`, "delete");
    const message = response?.message;
    handleApiResponseSuccess(message);
    return response.data;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

export const addShareToMostBoughtList = async (asset) => {
  try {
    const response = await makereq(`api/mb/${asset.assetid}`, "post");
    const message = response?.message;
    handleApiResponseSuccess(message);
    return response;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

// Top Analyst api
export const topAnalystList = async () => {
  try {
    const response = await makereq(`api/analystPick`, "get");
    return response.data;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

export const deleteAssetTopAnalyst = async (id) => {
  try {
    const response = await makereq(`api/analystPick/${id}`, "delete");
    const message = response?.message;
    handleApiResponseSuccess(message);
    return response.data;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

export const addShareToTopAnalyst = async (asset) => {
  try {
    const response = await makereq(`api/analystPick/${asset.assetid}`, "post");
    const message = response?.message;
    handleApiResponseSuccess(message);
    return response;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

// default list api
export const defaultList = async () => {
  try {
    const response = await makereq(`api/defaultList`, "get");
    return response.data;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

export const deleteAssetDefault = async (id) => {
  try {
    const response = await makereq(`api/defaultList/${id}`, "delete");
    const message = response?.message;
    handleApiResponseSuccess(message);
    return response.data;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

export const addShareToDefaultList = async (asset) => {
  try {
    const response = await makereq(`api/defaultList/${asset.assetid}`, "post");
    const message = response?.message;
    handleApiResponseSuccess(message);
    return response;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

// contact api
export const contactUsList = async () => {
  try {
    const response = await makereq(`api/contactus`, "get");
    return response.data;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

export const deleteContactUs = async (id) => {
  try {
    // const response = await axios.delete(`api/defaultList/${id}`, );
    const response = await makereq(
      `http://localhost:5000/api/contact/message/${id}`,
      "delete"
    );
    const message = response?.data?.message;
    handleApiResponseSuccess(message);
    return response.data;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

// sent a message
// api/contactUs/message/:id
export const sendMessage = async (message, Id) => {
  try {
    const response = await makereq(
      `api/contactUs/message/${Id}`,
      "put",
      message
    );
    const responseMessage = response?.data?.message;
    handleApiResponseSuccess(responseMessage);
    return response;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

export const searchShare = async (name) => {
  try {
    const response = await makereq(
      `api/shares/searchShareByName/${name}`,
      "get"
    );
    return response.data;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

// popular share module api
export const popularSharelist = async () => {
  try {
    const response = await makereq(`api/popularStocks`, "get");
    return response.data;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

export const deletePopularShare = async (id) => {
  try {
    const response = await makereq(`api/popularStocks/${id}`, "delete");
    const message = response?.message;
    handleApiResponseSuccess(message);
    return response.data;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

export const addShareToPopularShare = async (asset) => {
  try {
    const response = await makereq(
      `api/popularStocks/${asset.assetid}`,
      "post"
    );
    const message = response?.message;
    handleApiResponseSuccess(message);
    return response;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

// popular ETFs module api
export const popularETFslist = async () => {
  try {
    const response = await makereq(`api/ETFS`, "get");
    return response.data;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

export const deletePopularETFs = async (id) => {
  try {
    const response = await makereq(`api/ETFS/${id}`, "delete");
    const message = response?.message;
    handleApiResponseSuccess(message);
    return response.data;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

export const addShareToPopularETFs = async (asset) => {
  try {
    const response = await makereq(`api/ETFS/${asset.assetid}`, "post");
    const message = response?.message;
    handleApiResponseSuccess(message);
    return response;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

// transection detailed get api
export const transectionDetails = async (id, orderId) => {
  try {
    const response = await makereq(
      `api/order/orderDetails/${id}/${orderId}`,
      "get"
    );
    return response.data;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

// contact api
export const notificationList = async () => {
  try {
    const response = await makereq(`api/notifications`, "get");
    return response.data;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

export const deleteNotification = async (id) => {
  try {
    const response = await makereq(`api/notifications/${id}`, "delete");
    const message = response?.message;
    handleApiResponseSuccess(message);
    return response.data;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

// sent a message
// api/contactUs/message/:id
export const sendNotification = async (data) => {
  try {
    const response = await makereq(`api/notifications/`, "post", data);
    response.message
      ? toaster("success", response.message)
      : toaster("success", "request succesufull");
    return response;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

// transfer Api
export const transactionAddfund = async () => {
  try {
    const response = await makereq(`api/payment/allPayment`, "get");
    return response.data;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

export const transactionWithdrawal = async (UserId) => {
  try {
    const response = await makereq(`api/transfer/byUser/${UserId}`, "get");
    const message = response?.data?.message;
    handleApiResponseSuccess(message);
    return response.data;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

// restart payment
export const restartPayment = async (Id) => {
  try {
    const response = await makereq(`api/payment/restartPayment/${Id}`, "put");
    response
      ? toaster("success", "response.data.message")
      : toaster("success", "request successfull");
    return response;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

// add funds report Api
export const transactionAddfundReport = async (id) => {
  try {
    const response = await makereq(`api/payment/paymentDetails/${id}`, "get");
    return response.data;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

export const allTransactionWithdrawal = async () => {
  try {
    const response = await makereq(`api/transfer/all-transfer`, "get");
    return response.data;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

export const getBuyOrderDetail = async () => {
  try {
    const response = await makereq(`api/order/allOrders/buy`, "get");
    return response.data;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

export const getSellOrderDetail = async () => {
  try {
    const response = await makereq(`api/order/allOrders/sell`, "get");
    return response.data;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

export const getPendingPaymentsCount = async () => {
  try {
    const response = await makereq(`api/payment/pending-count`, "get");
    return response.data;
  } catch (e) {}
};

export const getDashboardCounts = async () => {
  try {
    const response = await makereq(`api/dashboard/counts`, "get");
    return response.data;
  } catch (e) {}
};

// admin list
export const getAdminList = async () => {
  try {
    const response = await makereq(`api/auth/subadmins`, "get");
    return response.data;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

// verify admin
export const verifyAdmin = async (id, data) => {
  try {
    const response = await makereq(
      `api/auth/subadmins/${id}/verify`,
      "patch",
      data
    );
    handleApiResponseSuccess(response?.message);
    return response;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

export const getReviewPendingList = async () => {
  try {
    const response = await makereq(`api/cip/cipAllUser`, "post");
    return response.data;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

export const verifyPendingCipUser = async (data) => {
  try {
    const response = await makereq(`api/cip/cipSubmit`, "post", data);
    handleApiResponseSuccess(response?.message);
    return response;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

export const updateAdminPassword = async (data) => {
  try {
    const response = await makereq(`api/auth/changePassAdmin`, "post", data);
    handleApiResponseSuccess(response?.message);
    return response;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

// get InProgess User Details

export const getUserInProgress = async () => {
  try {
    const response = await makereq(
      `api/auth/getUsersInProgressByAdmin`,
      "post"
    );
    return response.data;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

// Create Risk Section Category
export const createRiskCategory = async (category) => {
  const formData = new FormData();
  if (
    category.categoryName &&
    category.categoryImage &&
    category.categoryImage.name
  ) {
    formData.append("categoryName", category.categoryName);
    formData.append(
      "categoryImage",
      category.categoryImage,
      category.categoryImage.name
    );
  } else {
    toaster("error", "Fill details completely");
    return false;
  }

  try {
    const response = await makereq(`api/risksection`, "post", formData);
    const message = response?.message;
    handleApiResponseSuccess(message);
    return response;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

// Get All Risk Category
export const manageRiskSection = async () => {
  try {
    const response = await makereq("api/risksection", "get");
    return response && response?.data && response.data;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};
// Api to delete Risk Category
export const deleteRiskCategory = async (id) => {
  try {
    const response = await makereq(`api/risksection/${id}`, "delete");
    const message = response?.message;
    handleApiResponseSuccess(message);
    return response;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};
// Api to getriskcategory by id
export const getRiskCategoryById = async (id) => {
  try {
    const response = await makereq(
      `api/risksection/getCategoryById/${id}`,
      "get"
    );
    return response.data;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};
// Api to update riskcategory
export const editRiskCategory = async (category, id) => {
  const formData = new FormData();
  if (category.categoryName) {
    formData.append("categoryName", category.categoryName);
  }
  if (category.categoryImage) {
    formData.append(
      "categoryImage",
      category.categoryImage,
      category.categoryImage.name
    );
  }
  try {
    const response = await makereq(`api/risksection/${id}`, "put", formData);
    response.message
      ? toaster("success", response.message)
      : toaster("success", "request succesufull");
    return response;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

// Get All Risk SubCategory
export const manageSubRiskSection = async (id) => {
  try {
    const response = await makereq(`api/risksection/subCategory/${id}`, "get");
    return response && response?.data && response.data;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};
// Create Risk Section SubCategory
export const createRiskSubCategory = async (category, id) => {
  const formData = new FormData();
  if (
    category.categoryName &&
    category.categoryImage &&
    category.categoryImage.name
  ) {
    formData.append("categoryName", category.categoryName);
    formData.append(
      "categoryImage",
      category.categoryImage,
      category.categoryImage.name
    );
  } else {
    toaster("error", "Fill details completely");
    return false;
  }

  try {
    const response = await makereq(
      `api/risksection/subCategory/${id}`,
      "post",
      formData
    );
    const message = response?.message;
    handleApiResponseSuccess(message);
    return response;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

// // Api to delete Risk SubCategory
export const deleteRiskSubCategory = async (id) => {
  try {
    const response = await makereq(
      `api/risksection/subCategory/${id}`,
      "delete"
    );
    const message = response?.message;
    handleApiResponseSuccess(message);
    return response;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

export const addShareToRiskSubCategory = async (category) => {
  let categoryid = category.category;
  let subcategoryid = category.subcategory;
  let assetid = category.assetid;
  try {
    const response = await makereq(
      `api/risksection/${categoryid}/${subcategoryid}/${assetid}`,
      "post"
    );
    const message = response?.message;
    handleApiResponseSuccess(message);
    return response;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

// Api to get Assets List for SubRiskCategory
export const manageRiskShare = async (categoryID, subcategoryId) => {
  try {
    const response = await makereq(
      `api/risksection/${categoryID}/${subcategoryId}`,
      "get"
    );
    return response.data;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

// delete Assets from RiskCategory
export const deleteAssetRiskCategory = async (asset_id) => {
  try {
    const response = await makereq(
      `api/risksection/riskAssets/${asset_id}`,
      "delete"
    );
    const message = response?.message;
    handleApiResponseSuccess(message);
    return response.data;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

// Api to get RiskSubCategoryById
export const getRiskSubCategoryById = async (id) => {
  try {
    const response = await makereq(
      `api/risksection/getSubCategoryById/${id}`,
      "get"
    );
    return response.data;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

// Api to update riskSubcategory
export const editRiskSubCategory = async (category, id) => {
  const formData = new FormData();
  if (category.categoryName) {
    formData.append("categoryName", category.categoryName);
  }
  if (category.categoryImage) {
    formData.append(
      "categoryImage",
      category.categoryImage,
      category.categoryImage.name
    );
  }
  try {
    const response = await makereq(
      `api/risksection/subCategory/${id}`,
      "put",
      formData
    );
    response.message
      ? toaster("success", response.message)
      : toaster("success", "request succesufull");
    return response;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

export const getAutomatedInvestmentByCategoryId = async (categoryID) => {
  try {
    const response = await makereq(
      `api/automated-investment/${categoryID}`,
      "get"
    );
    return response.data;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

export const addAssetToAutomatedInvestmentByRiskId = async (payload) => {
  let categoryid = payload.category;
  let assetid = payload.assetid;
  try {
    const response = await makereq(
      `/api/automated-investment/${categoryid}/${assetid}`,
      "post"
    );
    const message = response?.message;
    handleApiResponseSuccess(message);
    return response;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};

export const deleteAutomatedInvestmentAssetByRiskIdAndAssetId = async (
  asset_id,
  categoryId
) => {
  try {
    const response = await makereq(
      `/api/automated-investment/${categoryId}/${asset_id}`,
      "delete"
    );
    const message = response?.message;
    handleApiResponseSuccess(message);
    return response.data;
  } catch (e) {
    handleApiResponseErrors(e);
  }
};
