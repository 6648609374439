import { put } from "redux-saga/effects";
import {
  authSuccess,
  isVadilforOtpVerification,
  loginFailed,
  loginSuccess,
  signUpFail,
  signUpSuccess,
  verifyOtpFailed,
} from "../actionCreators/authenticateActions";
import qs from "qs";
import Storage from "../../utils/storage";
import { makereq, toaster } from "../../utils/utils";

const headers = {
  "Content-Type": "application/x-www-form-urlencoded",
};

export function* loginSaga({ payload }) {
  try {
    var data = qs.stringify({
      email: payload.email,
      password: payload.password,
    });
    const res = yield makereq("api/auth/adminlogin", "post", data, headers);
    const { status, message } = res;
    if (status) {
      toaster("success", message);
      yield put(isVadilforOtpVerification());
    }
  } catch (error) {
    yield put(loginFailed());
    toaster("error", error);
  }
}

export function* verifyOtp({ payload }) {
  try {
    var data = qs.stringify({
      email: payload.email,
      otp: payload.otp,
    });
    const res = yield makereq(
      "api/auth/admin-otp-verify",
      "post",
      data,
      headers
    );
    if (res.status) {
      const { data, message } = res;
      const { email, token, userType, _id } = data;
      toaster("success", message);
      yield put(
        loginSuccess({
          email,
          token,
          userType,
          _id,
        })
      );
      Storage.saveToken(token);
    }
  } catch (error) {
    toaster("error", error);
    yield put(verifyOtpFailed());
  }
}

export function* authSaga() {
  try {
    const res = yield makereq("api/auth/getProfile", "post");
    yield put(authSuccess(res.data));
    Storage.setUserLoggedin();
  } catch (error) {
    toaster("error", error.message);
  }
}

export function* signupSaga({ payload }) {
  try {
    const res = yield makereq("api/auth/register/admin", "post", payload);
    if (res.status) {
      toaster("success", res.message);
      yield put(signUpSuccess());
    }
  } catch (error) {
    toaster("error", error);
    yield put(signUpFail());
  }
}
