import Storage from "../../utils/storage";
import { authentication } from "../actionTypes";

const intitalState = {
  userData: {},
  loggedin: Storage.isUserLoggedin() || false,
  token: Storage.loadToken() || null,
  loading: false,
  isVadilforOtpVerification: false,
  isAdminRegistered: false,
};

export const authReducer = (state = intitalState, action) => {
  const { payload, type } = action;
  switch (type) {
    case authentication.SIGNUP_INIT:
      return {
        ...state,
        loading: true,
        isAdminRegistered: false,
      };
    case authentication.SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        isAdminRegistered: true,
      };
    case authentication.SIGNUP_FAIL:
      return {
        ...state,
        loading: false,
      };
    case authentication.LOGIN_INIT:
      return {
        ...state,
        loading: true,
      };
    case authentication.VERIFY_OTP:
      return {
        ...state,
        loading: true,
      };
    case authentication.VERIFY_OTP_FAILED:
      return {
        ...state,
        loading: false,
      };
    case authentication.IS_VALIDE_FOR_OTP_VERIFICATION:
      return {
        ...state,
        loading: false,
        isVadilforOtpVerification: true,
      };

    case authentication.LOGIN_SUCCESS:
      return {
        ...state,
        token: payload.token,
        userData: payload,
        loggedin: true,
        loading: false,
        isVadilforOtpVerification: false,
      };

    case authentication.LOGIN_FAILED:
      Storage.deleteToken();
      return {
        ...state,
        loggedin: false,
        token: null,
        loading: false,
        backenderror: action.payload,
        isVadilforOtpVerification: false,
      };

    case authentication.AUTH_SUCCESS:
      return {
        ...state,
        userData: payload,
        loggedin: true,
        isVadilforOtpVerification: false,
      };

    case authentication.AUTH_FAILED:
      Storage.deleteToken();
      return {
        ...state,
        loggedin: false,
        token: null,
        loading: false,
        backenderror: payload,
        isVadilforOtpVerification: false,
      };

    case authentication.LOGOUT: {
      Storage.deleteToken();
      return {
        userData: {},
        loggedin: false,
        token: null,
        loading: false,
        isVadilforOtpVerification: false,
      };
    }

    default:
      return {
        ...state,
      };
  }
};
