import { takeLatest } from "redux-saga/effects";

import {
  editSettingsSaga,
  fetchSettingsSaga,
  setUserSettingsSaga,
} from "./sagas/settingsSaga";

import {
  authSaga,
  loginSaga,
  verifyOtp,
  signupSaga,
} from "./sagas/authenticationSaga";

import { fetchShopSaga, fetchProductSaga } from "./sagas/fetchSagas";

import {
  addVeicleSaga,
  editVehicleSaga,
  fetchvehicleSaga,
  deleteVehicleSaga,
  fetchPrivacypolicySaga,
  fetchTermsOfUseSaga,
} from "./sagas/vehicleSaga";

import {
  addproductcategorySaga,
  editproductcategorySaga,
  fetchProductCategorySaga,
  deleteProductCategorySaga,
} from "./sagas/productcategorySaga";

import {
  addShopcategorySaga,
  editshopcategorySaga,
  fetchshopcategorySaga,
  deleteshopcategorySaga,
} from "./sagas/shopcategorySaga";

import {
  addStaticPageSaga,
  editStaticPageSaga,
  fetchStaticPageSaga,
  deletestaticpageSaga,
} from "./sagas/staticPagesSaga";

import {
  deleteSubCatSaga,
  addSubcategorySaga,
  editSubcategorySaga,
  fetchproductsubcategorySaga,
  fetchallSubCategorySaga,
} from "./sagas/subcategorySagas";

import {
  addVehicleCategorySaga,
  editVehicleCategorySaga,
  fetchVehicleCategorySaga,
  deleteVehicleCategorySaga,
} from "./sagas/vehicleCategorySaga";

import { fetchCategorySaga } from "./sagas/categorySaga";

import {
  shopActions,
  vehicleActions,
  authentication,
  productActions,
  settingsActions,
  staticPageActions,
  shopcategoryActions,
  productCategoryActions,
  vehicalCategoryActions,
  productSubcategoryActions,
  transactionActions,
  orderActions,
  userActions,
  categoryActions,
  riskActions,
} from "./actionTypes";
import { fetchAllTransactionsSaga } from "./sagas/transactionSagas";
import {
  fetchAllUsersSaga,
  toggleActivation,
  userApprovalActivation,
  userDeleteSaga,
} from "./sagas/userSaga";
import { fetchAllOrderssSaga } from "./sagas/orderSagas";
import {
  fetchRiskCategorySaga,
  fetchSubRiskCategorySaga,
} from "./sagas/riskSaga";

export function* watcher() {
  yield takeLatest(authentication.LOGIN_INIT, loginSaga);
  yield takeLatest(authentication.VERIFY_OTP, verifyOtp);
  yield takeLatest(authentication.AUTH_INIT, authSaga);
  yield takeLatest(authentication.SIGNUP_INIT, signupSaga);

  yield takeLatest(userActions.FETCH_USERS_INITIATE, fetchAllUsersSaga);
  yield takeLatest(userActions.SET_USER_INITIATE, toggleActivation);
  yield takeLatest(
    userActions.SET_USER_APPROVAL_INITIATE,
    userApprovalActivation
  );
  yield takeLatest(userActions.DELETE_USER_INITIATE, userDeleteSaga);

  yield takeLatest(
    transactionActions.FETCH_TRANSACTIONS_INITIATE,
    fetchAllTransactionsSaga
  );
  yield takeLatest(orderActions.FETCH_ORDER_INITIATE, fetchAllOrderssSaga);

  // -----------------------vehicle-------------------------------------------
  yield takeLatest(vehicleActions.ADD_VEHICLE_INITIATTE, addVeicleSaga);
  yield takeLatest(vehicleActions.EDIT_VEHICLE_INITIATE, editVehicleSaga);
  yield takeLatest(vehicleActions.FETCH_VEHICLE_INITIATE, fetchvehicleSaga);
  yield takeLatest(vehicleActions.DELETE_VEHICLE_INITIATE, deleteVehicleSaga);

  yield takeLatest(
    vehicalCategoryActions.ADD_VEHICLE_CATEGORY_INITIATE,
    addVehicleCategorySaga
  );
  yield takeLatest(
    vehicalCategoryActions.EDIT_VEHICLE_CATEGORY_INITIATE,
    editVehicleCategorySaga
  );
  yield takeLatest(
    vehicalCategoryActions.FETCH_VEHICLE_CATEGORY_INITIATE,
    fetchVehicleCategorySaga
  );
  yield takeLatest(
    vehicalCategoryActions.DELETE_VEHICLE_CATEGORY_INITIATE,
    deleteVehicleCategorySaga
  );

  yield takeLatest(shopActions.FETCH_SHOP_INITIATE, fetchShopSaga);
  yield takeLatest(productActions.FETCH_PRODUCT_INITIATE, fetchProductSaga);

  yield takeLatest(
    productCategoryActions.ADD_PRODUCTCATEGORY_INITIATE,
    addproductcategorySaga
  );
  yield takeLatest(
    productCategoryActions.EDIT_PRODUCTCATEGORY_INITIATE,
    editproductcategorySaga
  );
  yield takeLatest(
    productCategoryActions.FETCH_PRODUCTCATEGORY_INITIATE,
    fetchProductCategorySaga
  );
  yield takeLatest(
    productCategoryActions.DELETE_PRODUCTCATEGORY_INITIATE,
    deleteProductCategorySaga
  );

  yield takeLatest(
    productSubcategoryActions.ADD_PRODUCTSUBCATEGORY_INITIATE,
    addSubcategorySaga
  );
  yield takeLatest(
    productSubcategoryActions.DELETE_PRODUCTSUBCATEGORY_INITIATE,
    deleteSubCatSaga
  );
  yield takeLatest(
    productSubcategoryActions.EDIT_PRODUCTSUBCATEGORY_INITIATE,
    editSubcategorySaga
  );
  yield takeLatest(
    productSubcategoryActions.FETCH_ALLSUBCATEGORY_INITIATE,
    fetchallSubCategorySaga
  );
  yield takeLatest(
    productSubcategoryActions.FETCH_PRODUCTSUBCATEGORY_INITIATE,
    fetchproductsubcategorySaga
  );
  // yield takeLatest(authentication.SIGNUP_INIT, signupSaga)

  yield takeLatest(
    shopcategoryActions.ADD_SHOPCATEGORY_INITIATTE,
    addShopcategorySaga
  );
  yield takeLatest(
    shopcategoryActions.EDIT_SHOPCATEGORY_INITIATE,
    editshopcategorySaga
  );
  yield takeLatest(
    shopcategoryActions.FETCH_SHOPCATEGORY_INITIATE,
    fetchshopcategorySaga
  );
  yield takeLatest(
    shopcategoryActions.DELETE_SHOPCATEGORY_INITIATE,
    deleteshopcategorySaga
  );

  yield takeLatest(
    settingsActions.SET_USER_SETTINGS_INITIATE,
    setUserSettingsSaga
  );
  yield takeLatest(settingsActions.EDIT_SETTINGS_INITIATE, editSettingsSaga);
  yield takeLatest(settingsActions.FETCH_SETTINGS_INITIATE, fetchSettingsSaga);

  yield takeLatest(
    staticPageActions.ADD_STATIC_PAGE_INITIATE,
    addStaticPageSaga
  );
  yield takeLatest(
    staticPageActions.EDIT_STATIC_PAGE_INITIATE,
    editStaticPageSaga
  );
  yield takeLatest(
    staticPageActions.FETCH_STATIC_PAGE_INITIATE,
    fetchStaticPageSaga
  );
  yield takeLatest(
    staticPageActions.DELETE_STATIC_PAGE_INITIATE,
    deletestaticpageSaga
  );

  yield takeLatest(
    staticPageActions.FETCH_PRIVACY_POLICY_INITIATE,
    fetchPrivacypolicySaga
  );
  yield takeLatest(
    staticPageActions.FETCH_TERMS_OF_USE_INITIATE,
    fetchTermsOfUseSaga
  );

  yield takeLatest(categoryActions.FETCH_CATEGORY_INITIATE, fetchCategorySaga);

  yield takeLatest(
    riskActions.FETCH_RISKCATEGORY_INITIATE,
    fetchRiskCategorySaga
  );
  yield takeLatest(
    riskActions.FETCH_RISK_SUB_CATEGORY_INITIATE,
    fetchSubRiskCategorySaga
  );
}
