// fetchproductsubcategory
import { put } from "redux-saga/effects";
import {
  deleteUserSuccess,
  fetchUsersSuccess,
  toggleActivationSuccess,
  userApprovalSuccess,
} from "../actionCreators/usersActions";
import { makereq, toaster } from "../../utils/utils";

var qs = require("qs");

export function* fetchAllUsersSaga({ payload }) {
  try {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const res = yield makereq(`api/auth/allUsers`, "get", {}, headers);
    const userdata = res.data;
    yield put(fetchUsersSuccess(userdata));
  } catch (error) {
    error.message
      ? toaster("error", error.message)
      : toaster("error", "Error Occured");
  }
}

export function* toggleActivation({ payload }) {
  try {
    var data = qs.stringify({
      _id: payload.id,
      status: payload.status,
    });

    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const res = yield makereq(
      `auth/update/${payload.id}`,
      "put",
      data,
      headers
    );
    const userList = res.data;
    yield put(userApprovalSuccess(userList));
  } catch (error) {}
}

export function* userApprovalActivation({ payload }) {
  try {
    var data = qs.stringify({
      _id: payload.id,
      Approval: payload.Approval,
    });

    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const res = yield makereq(
      `auth/approveUser/${payload.id}`,
      "put",
      data,
      headers
    );
    const userList = res.data;
    yield put(toggleActivationSuccess(userList));
  } catch (error) {}
}

export function* userDeleteSaga({ payload }) {
  try {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    const res = yield makereq(
      `api/auth/deleteUserAcc/${payload}`,
      "delete",
      null,
      headers
    );
    yield put(deleteUserSuccess({ payload }));
    toaster("success", res?.message);
  } catch (error) {
    toaster("error", error);
  }
}
