import { staticPageActions } from "../actionTypes";

export const fetchstaticPageInitiate = (data) => {
  return {
    type: staticPageActions.FETCH_STATIC_PAGE_INITIATE,
    payload: data,
  };
};

export const fetchstaticPageSuccess = (data) => {
  return {
    type: staticPageActions.FETCH_STATIC_PAGE_SUCCESS,
    payload: data,
  };
};

export const fetchstaticPageFail = () => {
  return {
    type: staticPageActions.FETCH_STATIC_PAGE_FAIL,
  };
};

export const addstaticPageInitiate = (data) => {
  return {
    type: staticPageActions.ADD_STATIC_PAGE_INITIATE,
    payload: data,
  };
};

export const addstaticPageSuccess = (data) => {
  return {
    type: staticPageActions.ADD_STATIC_PAGE_SUCCESS,
    payload: data,
  };
};

export const addstaticPageFail = () => {
  return {
    type: staticPageActions.ADD_STATIC_PAGE_FAIL,
  };
};

export const deletestaticPageInitiate = (data) => {
  return {
    type: staticPageActions.DELETE_STATIC_PAGE_INITIATE,
    payload: data,
  };
};

export const deletestaticPageSuccess = (data) => {
  return {
    type: staticPageActions.DELETE_STATIC_PAGE_SUCCESS,
    payload: data,
  };
};

export const editstaticPageInitiate = (data) => {
  return {
    type: staticPageActions.EDIT_STATIC_PAGE_INITIATE,
    payload: data,
  };
};

export const editstaticPageSuccess = (data) => {
  return {
    type: staticPageActions.EDIT_STATIC_PAGE_SUCCESS,
    payload: data,
  };
};
