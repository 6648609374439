export const noIntenetConnection =
  "No Internet Connection!, Please Check Your Internet Connection";
export const somehingwentWrong = "Something went wrong, please try again later";
export const requestSuccess = "Request Successfull Executed";

export const deleteCategoryHeading = "Delete Asset";
export const deleteCategoryMsg = "Are you sure want to delete this asset?";

export const deleteNotificationHeading = "Delete Notification";
export const deleteNotificationMsg =
  "Are you sure want to delete this Notification?";

export const deleteStaticContentHeading = "Delete Static Content";
export const deleteStaticContentMsg =
  "Are you sure want to delete this Static Content?";

export const deleteScriptHeading = "Delete Script";
export const deleteScriptMsg = "Are you sure want to delete this Script?";

export const deleteCategoryHeadingMsg = "Delete Category";
export const deleteCategoryWarningMsg =
  "Are you sure want to delete this category?";

export const withdrawalfundTransactionEmptyUserid = "Please Enter User Id!!!";

export const adminProfile = "Complete Your Profile";
