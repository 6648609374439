import { put } from "redux-saga/effects";
import {
  fetchCategoryFail,
  fetchCategorySuccess,
} from "../actionCreators/categoryAction";
import { manageCategory } from "../../services/api";

export function* fetchCategorySaga() {
  try {
    const res = yield manageCategory();
    yield put(fetchCategorySuccess(res));
  } catch (error) {
    yield put(fetchCategoryFail());
  }
}
