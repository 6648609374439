import {
  Route,
  Routes,
  Navigate,
  unstable_HistoryRouter as HistoryRouter,
} from "react-router-dom";

import ProtectedRoute from "./components/ProtectedRoute";
import Notfound from "./components/PageNotFound/notfound";
import Header from "./components/home/header";
import AdminLtSide from "./components/home/adminltsidebar";
import { authInit, logout } from "./redux/actionCreators/authenticateActions";
import { useDispatch, useSelector } from "react-redux";
import { routes } from "./routes";
import { Suspense, useCallback } from "react";
import AuthVerify from "./AuthVerify";
import customHistory from "./history";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ErrorBoundary from "./components/ErrorBoundary";
import { loader } from "./utils/helpers";
import Storage from "./utils/storage";

function RouteConfig() {
  const dispatch = useDispatch();
  const loggedin = useSelector((state) => state.adminState.loggedin);

  if (Storage.loadToken()) {
    dispatch(authInit(Storage.loadToken()));
  }

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  return (
    <HistoryRouter history={customHistory}>
      <ErrorBoundary>
        <Suspense
          fallback={
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "100vh" }}
            >
              {loader()}
            </div>
          }
        >
          <div className="wrapper">
            {loggedin && <Header />}
            <AdminLtSide />
          </div>

          <Routes>
            {routes.map((route, ind) => {
              return route.isProtected ? (
                <Route
                  key={ind}
                  path={route.path}
                  element={<ProtectedRoute>{route.element}</ProtectedRoute>}
                />
              ) : (
                <Route key={ind} path={route.path} element={route.element} />
              );
            })}
            <Route
              path="/"
              element={
                loggedin ? <Navigate to="/home" /> : <Navigate to="/sign-in" />
              }
            />
            <Route path="*" element={<Notfound />} />
          </Routes>

          <AuthVerify logOut={logOut} />
          <ToastContainer />
        </Suspense>
      </ErrorBoundary>
    </HistoryRouter>
  );
}

export default RouteConfig;
