import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Storage from "./utils/storage";

const AuthVerify = (props) => {
  let location = useLocation();

  useEffect(() => {
    const user = Storage.loadToken();
    if (user) {
      const decodedJwt = jwt_decode(user);
      if (decodedJwt.exp * 1000 < Date.now()) {
        props.logOut();
      }
    } else {
      props.logOut();
    }
  }, [location, props]);

  return <></>;
};

export default AuthVerify;
