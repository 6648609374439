import { shopcategoryActions } from "../actionTypes";

export const addShopCategoryInitiate = (data) => {
  return {
    type: shopcategoryActions.ADD_SHOPCATEGORY_INITIATTE,
    payload: data,
  };
};

export const addShopCategorySuccess = (data) => {
  return {
    type: shopcategoryActions.ADD_SHOPCATEGORY_SUCCESS,
    payload: data,
  };
};

export const deleteShopCategoryInitiate = (data) => {
  return {
    type: shopcategoryActions.DELETE_SHOPCATEGORY_INITIATE,
    payload: data,
  };
};

export const deleteShopCategorySuccess = (data) => {
  return {
    type: shopcategoryActions.DELETE_SHOPCATEGORY_SUCCESS,
    payload: data,
  };
};

export const fetchShopCategoryInitiate = (data) => {
  return {
    type: shopcategoryActions.FETCH_SHOPCATEGORY_INITIATE,
    payload: data,
  };
};

export const fetchShopCategorySuccess = (data) => {
  return {
    type: shopcategoryActions.FETCH_SHOPCATEGORY_SUCCESS,
    payload: data,
  };
};

export const editShopCategoryInitiate = (data) => {
  return {
    type: shopcategoryActions.EDIT_SHOPCATEGORY_INITIATE,
    payload: data,
  };
};

export const editShopCategorySuccess = (data) => {
  return {
    type: shopcategoryActions.EDIT_SHOPCATEGORY_SUCCESS,
    payload: data,
  };
};
