import { riskActions } from "../actionTypes";

const intitalState = {
  riskCategory: [],
  loading: false,
};

const intitalState1 = {
  riskSubCategory: [],
  loading: false,
};

export const riskCategoryReducer = (state = intitalState, action) => {
  const { payload, type } = action;
  switch (type) {
    case riskActions.FETCH_RISKCATEGORY_INITIATE: {
      return {
        ...state,
        loading: true,
      };
    }
    case riskActions.FETCH_RISKCATEGORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        riskCategory: payload,
      };
    }
    case riskActions.FETCH_RISKCATEGORY_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export const riskSubCategoryReducer = (state = intitalState1, action) => {
  const { payload, type } = action;
  switch (type) {
    case riskActions.FETCH_RISK_SUB_CATEGORY_INITIATE: {
      return {
        ...state,
        loading: true,
      };
    }
    case riskActions.FETCH_RISK_SUB_CATEGORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        riskSubCategory: payload,
      };
    }
    case riskActions.FETCH_RISK_SUB_CATEGORY_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
