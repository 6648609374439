import { css } from "@emotion/react";
import { BeatLoader } from "react-spinners";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export function formatAMPM(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  const strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

export const loader = () => (
  <div className="loader">
    <BeatLoader
      color={"#66ff66"}
      loading={true}
      css={override}
      margin={5}
      size={15}
    />
  </div>
);

export const noRecords = (msg) => (
  <div className="text-center fw-bold">{msg ? msg : "No Data Found!"}</div>
);

export const getDateandTime = (tabledata, header) => {
  const date = new Date(tabledata[header.field]);
  return (
    date.getMonth() +
    1 +
    "/" +
    date.getDate() +
    "/" +
    date.getFullYear() +
    " " +
    formatAMPM(date)
  );
};
