import { useState, useEffect, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actionCreators/authenticateActions";
import { Button } from "reactstrap";
import { adminProfile } from "../../utils/alertMessages";
import { fetchSettingsInitiate } from "../../redux/actionCreators/settingsActions";
import isEmpty from "lodash/isEmpty";
import { loader } from "../../utils/helpers";

const handleAdminProfile = (loading, isProfiledataAvailable) => {
  const profileIcon = <i className="fas fa-user-cog" />;
  return loading ? (
    loader()
  ) : isProfiledataAvailable ? (
    profileIcon
  ) : (
    <div
      type="button"
      className="position-relative"
      data-bs-toggle="tooltip"
      title={adminProfile}
    >
      {profileIcon}
      <span className="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle"></span>
    </div>
  );
};

const Header = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { settingsData, loading } = useSelector((state) => state.settingsState);
  const { _id } = useSelector((state) => state.adminState?.userData);
  const [isProfiledataAvailable, setIsProfiledataAvailable] = useState(false);
  const hideComponent =
    location?.pathname?.includes("/transection/detail/") ||
    location?.pathname?.includes("/FAQ");

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleProfileSettings = useCallback(() => {
    if (_id) dispatch(fetchSettingsInitiate(_id));
  }, [dispatch, _id]);

  useEffect(() => {
    if (!isEmpty(settingsData)) setIsProfiledataAvailable(true);
  }, [settingsData]);

  useEffect(() => {
    handleProfileSettings();
  }, [handleProfileSettings]);

  return (
    !hideComponent && (
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        {/* <!-- Left navbar links --> */}

        <ul className="navbar-nav">
          <li className="nav-item">
            <Link className="nav-link" data-widget="pushmenu" role="button">
              <i className="fas fa-bars" />
            </Link>
          </li>
          <li className="nav-item d-none d-sm-inline-block">
            <Link to="/home" className="nav-link">
              Home
            </Link>
          </li>
        </ul>

        {/* <!-- Right navbar links --> */}
        <ul className="navbar-nav ml-auto ">
          <li className="nav-item">
            <Link className="nav-link" data-widget="fullscreen" role="button">
              <i className="fas fa-expand-arrows-alt" />
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/admin-settings">
              <span
                className="nav-link"
                data-widget="control-sidebar"
                data-slide="true"
                role="button"
              >
                {handleAdminProfile(loading, isProfiledataAvailable)}
                {/* <div
                  type="button"
                  className="position-relative"
                  data-bs-toggle="tooltip"
                  title={adminProfile}
                >
                  <i className="fas fa-user-cog"></i>
                  <span className="position-absolute top-0 start-0 translate-middle p-2 bg-danger border border-light rounded-circle"></span>
                </div> */}
                {/* <i className="fas fa-user-cog"></i> */}
              </span>
            </Link>
          </li>
          <li className="nav-item" onClick={handleLogout}>
            <Link
              className="nav-link d-flex  justify-content-center align-items-center "
              data-slide="true"
              role="button"
            >
              <Button className="float-end" color="danger" size="sm">
                Logout
              </Button>
            </Link>
          </li>
        </ul>
      </nav>
    )
  );
};
export default Header;
