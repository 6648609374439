// fetchproductsubcategory
import { put } from "redux-saga/effects";
import { fetchTransactionsSuccess } from "../actionCreators/transactionActions";
import { makereq } from "../../utils/utils";

export function* fetchAllTransactionsSaga() {
  try {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const res = yield makereq(
      `api/transfer/allTransferUser`,
      "get",
      {},
      headers
    );
    const transData = res.data;
    yield put(fetchTransactionsSuccess(transData));
  } catch (error) {}
}
