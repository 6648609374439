import { lazy } from "react";
import componentLoader from "./utils/utils";

const Home = lazy(() => componentLoader(() => import("./components/home")));
const AdminList = lazy(() =>
  componentLoader(() => import("./components/home/AdminList"))
);
const Login = lazy(() =>
  componentLoader(() => import("./components/login/login"))
);
const Register = lazy(() =>
  componentLoader(() => import("./components/register/index"))
);
const TermsOfUs = lazy(() =>
  componentLoader(() => import("./components/home/termsOfuse"))
);
const SettingsDisp = lazy(() =>
  componentLoader(() => import("./components/home/settings"))
);
const StaticPages = lazy(() =>
  componentLoader(() => import("./components/home/staticpage"))
);

const AddStaticPage = lazy(() =>
  componentLoader(() => import("./components/home/addStaticPage"))
);
const PrivacyPolicy = lazy(() =>
  componentLoader(() => import("./components/home/privacypolicy"))
);
const TransactionDisplay = lazy(() =>
  componentLoader(() => import("./components/home/transaction"))
);
const TransactionAddFundDisplay = lazy(() =>
  componentLoader(() => import("./components/home/transactionAddFund"))
);
const TransactionDetail = lazy(() =>
  componentLoader(() => import("./components/home/transactionDetail"))
);

const User = lazy(() =>
  componentLoader(() => import("./components/home/user"))
);
const Category = lazy(() =>
  componentLoader(() => import("./components/category/addShare"))
);
const CreateCategory = lazy(() =>
  componentLoader(() => import("./components/category/createCategory"))
);
const CategoryManage = lazy(() =>
  componentLoader(() => import("./components/category/categoryManage"))
);
const ShareManage = lazy(() =>
  componentLoader(() => import("./components/category/shareManage"))
);
const DefaultShare = lazy(() =>
  componentLoader(() => import("./components/defaultShare/defaultshareManage"))
);

const ContactUsDisplay = lazy(() =>
  componentLoader(() => import("./components/home/contactUsDisplay"))
);
const ContactReply = lazy(() =>
  componentLoader(() => import("./components/home/contactReply"))
);

const FAQ = lazy(() => componentLoader(() => import("./components/FAQ/FAQ")));
const HelpAndSupport = lazy(() =>
  componentLoader(() => import("./components/Help&Support/help&support"))
);
const UserActions = lazy(() =>
  componentLoader(() => import("./components/home/UserActions"))
);
const AddFundsReport = lazy(() =>
  componentLoader(() => import("./components/home/AddFundsReport"))
);
const WithdrawFundsReport = lazy(() =>
  componentLoader(() => import("./components/home/WithdrawFundsReport"))
);
const FormAddEdit = lazy(() =>
  componentLoader(() => import("./components/home/activationForm"))
);
const AddDefaultShare = lazy(() =>
  componentLoader(() => import("./components/defaultShare/addDefaultShare.js"))
);
const AddPopularShare = lazy(() =>
  componentLoader(() => import("./components/popularShare/addPopularShare"))
);
const PopularShare = lazy(() =>
  componentLoader(() => import("./components/popularShare/popularShareManage"))
);
const AddMostBoughtShare = lazy(() =>
  componentLoader(() => import("./components/mostBought/addmostBoughtShare"))
);
const MostBoughtShare = lazy(() =>
  componentLoader(() => import("./components/mostBought/mostBoughtshareManage"))
);
const AddETFsShare = lazy(() =>
  componentLoader(() => import("./components/ETFs/addETFsShare"))
);
const ETFsShare = lazy(() =>
  componentLoader(() => import("./components/ETFs/ETFsShareManage"))
);
const AddTopAnalystPickShare = lazy(() =>
  componentLoader(() =>
    import("./components/TopAnalystPick/addTopAnalystPickShare")
  )
);
const TopAnalystPickShare = lazy(() =>
  componentLoader(() =>
    import("./components/TopAnalystPick/topAnalystPickshareManage")
  )
);
const NotificationManage = lazy(() =>
  componentLoader(() => import("./components/notification/notificationManage"))
);
const Notification = lazy(() =>
  componentLoader(() => import("./components/notification/sendNotification"))
);
const TransectionWithdraw = lazy(() =>
  componentLoader(() => import("./components/home/transactionWithdraw.js"))
);
const BuyOrderReport = lazy(() =>
  componentLoader(() => import("./components/home/BuyOrderReport"))
);
const SellOrderReport = lazy(() =>
  componentLoader(() => import("./components/home/SellOrderReport"))
);
const ReviewUser = lazy(() =>
  componentLoader(() => import("./components/home/review-user"))
);

const ChangePassword = lazy(() =>
  componentLoader(() => import("./components/home/ChangePassword"))
);

const UserInProgress = lazy(() =>
  componentLoader(() => import("./components/home/inProgressUserlist"))
);
const RiskSection = lazy(() =>
  componentLoader(() => import("./components/RiskSection/categoryManage"))
);

const RiskCategoryCreate = lazy(() =>
  componentLoader(() => import("./components/RiskSection/createCategory"))
);

const RiskCategory = lazy(() =>
  componentLoader(() => import("./components/RiskSection/addShare"))
);

const RiskSubCategoryCreate = lazy(() =>
  componentLoader(() => import("./components/RiskSection/createSubCategory"))
);

const RiskSubCategoryManage = lazy(() =>
  componentLoader(() => import("./components/RiskSection/subcategoryManage"))
);

const RiskShareAdd = lazy(() =>
  componentLoader(() => import("./components/RiskSection/addShare"))
);
const RiskShareManage = lazy(() =>
  componentLoader(() => import("./components/RiskSection/shareManage"))
);
const AutomatedInvestmentShareList = lazy(() =>
  componentLoader(() => import("./components/AutomatedInvestment/shareManage"))
);

const AddAutomatedInvestmentAsset = lazy(() =>
  componentLoader(() => import("./components/AutomatedInvestment/addShare"))
);
export const routes = [
  {
    path: "/sign-in",
    element: <Login />,
    isProtected: false,
  },
  {
    path: "/sign-up",
    element: <Register />,
    isProtected: false,
  },
  {
    path: "/FAQ",
    element: <FAQ />,
    isProtected: false,
  },
  {
    path: "/H&S",
    element: <HelpAndSupport />,
    isProtected: false,
  },
  {
    path: "/admin-list",
    element: <AdminList />,
    isProtected: true,
  },
  {
    path: "/user-view",
    element: <UserActions />,
    isProtected: true,
  },
  {
    path: "/addfund-report",
    element: <AddFundsReport />,
    isProtected: true,
  },
  {
    path: "/withdrawalfund-report",
    element: <WithdrawFundsReport />,
    isProtected: true,
  },
  {
    path: "/buyOrders-report",
    element: <BuyOrderReport />,
    isProtected: true,
  },
  {
    path: "/sellOrders-report",
    element: <SellOrderReport />,
    isProtected: true,
  },
  {
    path: "/transection/detail/:id/:orderId",
    element: <TransactionDetail />,
    isProtected: false,
  },
  {
    path: "/transactions/addfund",
    element: <TransactionAddFundDisplay />,
    isProtected: true,
  },
  {
    path: "/transactions",
    element: <TransectionWithdraw />,
    isProtected: true,
  },
  {
    path: "/transactions/withdraw/:id",
    element: <TransactionDisplay />,
    isProtected: true,
  },
  {
    path: "/form/:id",
    element: <FormAddEdit />,
    isProtected: true,
  },
  {
    path: "/admin-settings",
    element: <SettingsDisp />,
    isProtected: true,
  },
  {
    path: "/static-pages",
    element: <StaticPages />,
    isProtected: true,
  },
  {
    path: "/static-pages/privacyPolicy",
    element: <PrivacyPolicy />,
    isProtected: true,
  },
  {
    path: "/static-pages/termsOfUse",
    element: <TermsOfUs />,
    isProtected: true,
  },
  {
    path: "/user",
    element: <User />,
    isProtected: true,
  },
  {
    path: "/contact-us",
    element: <ContactUsDisplay />,
    isProtected: true,
  },
  {
    path: "/contact/reply/:id",
    element: <ContactReply />,
    isProtected: true,
  },
  {
    path: "/category",
    element: <CategoryManage />,
    isProtected: true,
  },
  {
    path: "/category/:name/:id",
    element: <CreateCategory />,
    isProtected: true,
  },
  {
    path: "/category/create",
    element: <CreateCategory />,
    isProtected: true,
  },
  {
    path: "/category/manage/share/:name/:id",
    element: <ShareManage />,
    isProtected: true,
  },
  {
    path: "/category/addShare",
    element: <Category />,
    isProtected: true,
  },
  {
    path: "/category/addShare/manage/:name/:id",
    element: <Category />,
    isProtected: true,
  },
  {
    path: "/default/asset",
    element: <DefaultShare />,
    isProtected: true,
  },
  {
    path: "/default/addasset",
    element: <AddDefaultShare />,
    isProtected: true,
  },
  {
    path: "/popular/asset",
    element: <PopularShare />,
    isProtected: true,
  },
  {
    path: "/popular/addasset",
    element: <AddPopularShare />,
    isProtected: true,
  },
  {
    path: "/mostbought/asset",
    element: <MostBoughtShare />,
    isProtected: true,
  },
  {
    path: "/mostbought/addasset",
    element: <AddMostBoughtShare />,
    isProtected: true,
  },
  {
    path: "/topanalystpick/addasset",
    element: <AddTopAnalystPickShare />,
    isProtected: true,
  },
  {
    path: "/topanalystpick/asset",
    element: <TopAnalystPickShare />,
    isProtected: true,
  },
  {
    path: "/ETFs/asset",
    element: <ETFsShare />,
    isProtected: true,
  },
  {
    path: "/ETFs/addasset",
    element: <AddETFsShare />,
    isProtected: true,
  },
  {
    path: "/Notification/list",
    element: <NotificationManage />,
    isProtected: true,
  },
  {
    path: "/Notification/send",
    element: <Notification />,
    isProtected: true,
  },
  {
    path: "/add-static-content",
    element: <AddStaticPage />,
    isProtected: true,
  },
  {
    path: "/home",
    element: <Home />,
    isProtected: true,
  },
  {
    path: "/review-user",
    element: <ReviewUser />,
    isProtected: true,
  },
  {
    path: "/changePassword",
    element: <ChangePassword />,
    isProtected: true,
  },
  {
    path: "/userinprogress",
    element: <UserInProgress />,
    isProtected: true,
  },
  {
    path: "/risksection",
    element: <RiskSection />,
    isProtected: true,
  },
  {
    path: "/riskCategory/:id",
    element: <RiskCategoryCreate />,
    isProtected: true,
  },
  {
    path: "/risksection/create",
    element: <RiskCategoryCreate />,
    isProtected: true,
  },
  {
    path: "/risksection/subcategory/:id",
    element: <ShareManage />,
    isProtected: true,
  },
  {
    path: "/risksection/subcategory/addShare/:id",
    element: <RiskShareAdd />,
    isProtected: true,
  },
  {
    path: "/risksection/addShare/manage/:id",
    element: <RiskCategory />,
    isProtected: true,
  },
  {
    path: "/risksection/createsubCategory/:id",
    element: <RiskSubCategoryCreate />,
    isProtected: true,
  },
  {
    path: "/risksection/createsubCategory/:update/:id",
    element: <RiskSubCategoryCreate />,
    isProtected: true,
  },
  {
    path: "/risksection/managesubcategory/:id",
    element: <RiskSubCategoryManage />,
    isProtected: true,
  },
  {
    path: "/risksection/manage/share/subcategory/:id/:id1",
    element: <RiskShareManage />,
    isProtected: true,
  },
  {
    path: "/automated-investment/:id",
    element: <AutomatedInvestmentShareList />,
    isProtected: true,
  },
  {
    path: "/automated-investment/add-asset/:id",
    element: <AddAutomatedInvestmentAsset />,
    isProtected: true,
  },
];
