import { put } from "redux-saga/effects";
import {
  addVehicleCategorySuccess,
  editVehicleCategorySuccess,
  fetchVehicleCategorySuccess,
  deleteVehicleCategorySuccess,
} from "../actionCreators/vehicalCategoryActions";
import { makereq, toaster } from "../../utils/utils";

var qs = require("qs");

export function* addVehicleCategorySaga({ payload }) {
  try {
    var data = qs.stringify({
      name: payload.name,
      description: payload.description,
    });

    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const res = yield makereq("vehicalCategory/", "post", data, headers);
    const vehicleData = res.data;
    yield put(addVehicleCategorySuccess({ vehicleData }));
  } catch (error) {}
}

export function* fetchVehicleCategorySaga({ payload }) {
  try {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const res = yield makereq("vehicalCategory/", "get", {}, headers);
    const { data } = res;
    yield put(fetchVehicleCategorySuccess({ data }));
  } catch (error) {
    error.message === "invalid token..."
      ? toaster("error", "please login again")
      : toaster("error", error.message);
  }
}

export function* deleteVehicleCategorySaga({ payload }) {
  try {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    yield makereq(`vehicalCategory/${payload}`, "delete", {}, headers);
    yield put(deleteVehicleCategorySuccess({ payload }));
  } catch (error) {}
}

export function* editVehicleCategorySaga({ payload }) {
  try {
    var data = qs.stringify({
      name: payload.name,
      description: payload.description,
    });
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    yield makereq(`vehicalCategory/${payload.id}`, "put", data, headers);
    yield put(editVehicleCategorySuccess({ payload }));
  } catch (error) {}
}
