import { productCategoryActions } from "../actionTypes";

const intitalState = {
  productCategorylist: [],
};

export const productCategoryReducer = (state = intitalState, action) => {
  const { payload, type } = action;
  switch (type) {
    case productCategoryActions.FETCH_PRODUCTCATEGORY_INITIATE: {
      return {
        ...state,
      };
    }

    case productCategoryActions.FETCH_PRODUCTCATEGORY_SUCCESS: {
      return {
        ...state,
        productCategorylist: payload.data,
      };
    }

    case productCategoryActions.DELETE_PRODUCTCATEGORY_INITIATE: {
      return {
        ...state,
      };
    }

    case productCategoryActions.DELETE_PRODUCTCATEGORY_SUCCESS: {
      const res = state.productCategorylist.filter(
        (itm) => itm._id !== payload.payload
      );
      return {
        ...state,
        productCategorylist: res,
      };
    }

    case productCategoryActions.ADD_PRODUCTCATEGORY_INITIATE: {
      return {
        ...state,
      };
    }

    case productCategoryActions.ADD_PRODUCTCATEGORY_SUCCESS: {
      const newObj = {
        _id: payload.data.id,
        name: payload.data.name,
        description: payload.data.description,
        image: payload.data.image,
      };
      const newlist = [...state.productCategorylist, newObj];
      return {
        ...state,
        productCategorylist: newlist,
      };
    }

    case productCategoryActions.EDIT_PRODUCTCATEGORY_INITIATE: {
      return {
        ...state,
      };
    }

    case productCategoryActions.EDIT_PRODUCTCATEGORY_SUCCESS: {
      const newObj = {
        _id: payload.updateddata.id,
        name: payload.updateddata.name,
        description: payload.updateddata.description,
        image: payload.updateddata.image,
      };
      let index = state.productCategorylist.findIndex((element) => {
        if (element._id === newObj._id) {
          return true;
        }
        return false;
      });
      const newArray = [...state.productCategorylist];
      newArray.splice(index, 1, newObj);
      return {
        ...state,
        productCategorylist: newArray,
      };
    }

    default:
      return {
        ...state,
      };
  }
};
