import { userActions } from "../actionTypes";

const intitalState = {
  users: [],
  loading: false,
};

export const userReducer = (state = intitalState, action) => {
  const { payload, type } = action;
  switch (type) {
    case userActions.FETCH_USERS_INITIATE: {
      return {
        ...state,
        loading: true,
      };
    }

    case userActions.FETCH_USERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        users: action.payload,
      };
    }

    case userActions.SET_USER_INITIATE: {
      return {
        ...state,
      };
    }
    case userActions.SET_USER_SUCCESS: {
      const res = state.users.filter((itm) => itm._id !== payload.users._id);
      const newres = [...res, payload.users];
      return {
        ...state,
        users: newres,
      };
    }

    case userActions.SET_USER_APPROVAL_INITIATE: {
      return {
        ...state,
      };
    }
    case userActions.SET_USER_APPROVAL_SUCCESS: {
      const res = state.users.filter((itm) => itm._id !== payload.users._id);
      const newres = [...res, payload.users];
      return {
        ...state,
        users: newres,
      };
    }
    case userActions.DELETE_USER_INITIATE: {
      return {
        ...state,
      };
    }
    case userActions.DELETE_USER_SUCCESS: {      
      const res = state.users.filter((itm) => itm?._id !== payload);
      const newres = [...res, payload.users];
      return {
        ...state,
        users: newres,
      };
    }
    
    default:
      return {
        ...state,
      };
  }
};
