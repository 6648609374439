import Storage from "../../utils/storage";
import { authentication } from "../actionTypes";

export const loginInit = (data) => {
  return {
    type: authentication.LOGIN_INIT,
    payload: data,
  };
};

export const verifyOtp = (data) => {
  return {
    type: authentication.VERIFY_OTP,
    payload: data,
  };
};

export const verifyOtpFailed = () => {
  return {
    type: authentication.VERIFY_OTP_FAILED,
  };
};

export const isVadilforOtpVerification = () => {
  return {
    type: authentication.IS_VALIDE_FOR_OTP_VERIFICATION,
  };
};

export const loginSuccess = (data) => {
  return {
    type: authentication.LOGIN_SUCCESS,
    payload: data,
  };
};

export const loginFailed = (data) => {
  return {
    type: authentication.LOGIN_FAILED,
    payload: data,
  };
};

export const signUpInit = (data) => {
  return {
    type: authentication.SIGNUP_INIT,
    payload: data,
  };
};

export const signUpSuccess = () => {
  return {
    type: authentication.SIGNUP_SUCCESS,
  };
};

export const signUpFail = () => {
  return {
    type: authentication.SIGNUP_FAIL,
  };
};

export const logout = () => {
  Storage.deleteToken();
  return {
    type: authentication.LOGOUT,
  };
};

export const authInit = (data) => {
  return {
    type: authentication.AUTH_INIT,
    payload: data,
  };
};

export const authSuccess = (data) => {
  return {
    type: authentication.AUTH_SUCCESS,
    payload: data,
  };
};

export const authFailed = (data) => {
  return {
    type: authentication.AUTH_FAILED,
    payload: data,
  };
};
