const storage = window.localStorage;

const Storage = {
  clear() {
    storage.clear();
  },
  saveToken(token) {
    try {
      storage.setItem("userData", token);
      storage.setItem("isUserLoggedin", JSON.stringify(true));
    } catch (error) {
      return null;
    }
  },
  isUserLoggedin() {
    try {
      return JSON.parse(storage.getItem("isUserLoggedin"));
    } catch (error) {
      return null;
    }
  },
  setUserLoggedin() {
    try {
      return storage.setItem("isUserLoggedin", JSON.stringify(true));
    } catch (error) {
      return null;
    }
  },
  loadToken() {
    try {
      return storage.getItem("userData");
    } catch (error) {
      return null;
    }
  },
  deleteToken() {
    try {
      storage.removeItem("userData");
      storage.removeItem("isUserLoggedin");
    } catch (error) {
      return null;
    }
  },
};

export default Storage;
