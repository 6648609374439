import { settingsActions } from "../actionTypes";

const intitalState = {
  settingsData: {},
  loading: false,
  isUserSettingCreated: false,
};

export const settingsReducers = (state = intitalState, action) => {
  const { payload, type } = action;
  switch (type) {
    case settingsActions.FETCH_SETTINGS_INITIATE: {
      return {
        ...state,
        loading: true,
        isUserSettingCreated: false,
      };
    }
    case settingsActions.FETCH_SETTINGS_SUCCESS: {
      const { data } = payload;
      const newObj = {
        name: data[0].name,
        email: data[0].email,
        password: data[0].password,
        sitename: data[0].sitename,
        smtpemail: data[0].smtpemail,
        smtpPort: data[0].smtpPort,
        smtpHost: data[0].smtpHost,
        smtppassword: data[0].smtppassword,
        mostBoughtQuantity: data[0].mostBoughtQuantity,
        defaultListQuantity: data[0].defaultListQuantity,
        ETFsquantity: data[0].ETFsquantity,
        commission: data[0].commission,
        popularquantity: data[0].popularquantity,
        watchlistQuantity: data[0].watchlistQuantity,
        addFundCommission: data[0].addFundCommission,
        withdrawFundCommission: data[0].withdrawFundCommission,
        adminRelationshipId: data[0].adminRelationshipId,
        adminAccountId: data[0].adminAccountId,
        iosAppVersion: data[0].iosAppVersion,
        iosAppUpdateForceFully: data[0].iosAppUpdateForceFully,
        androidAppVersion: data[0].androidAppVersion,
        androidAppUpdateForceFully: data[0].androidAppUpdateForceFully,
        _id: data[0]._id,
      };
      return {
        ...state,
        settingsData: newObj,
        loading: false,
      };
    }
    case settingsActions.EDIT_SETTINGS_INITIATE: {
      return {
        ...state,
        loading: true,
        isUserSettingCreated: false,
      };
    }
    case settingsActions.EDIT_SETTINGS_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case settingsActions.SET_USER_SETTINGS_INITIATE: {
      return {
        ...state,
        loading: true,
        isUserSettingCreated: false,
      };
    }
    case settingsActions.SET_USER_SETTINGS_SUCCESS: {
      return {
        ...state,
        loading: false,
        isUserSettingCreated: true,
      };
    }
    case settingsActions.SET_USER_SETTINGS_FAIL: {
      return {
        ...state,
        loading: false,
        isUserSettingCreated: false,
      };
    }
    case settingsActions.EDIT_SETTINGS_FAIL: {
      return {
        ...state,
        loading: false,
        isUserSettingCreated: false,
      };
    }
    case settingsActions.FETCH_SETTINGS_FAIL: {
      return {
        ...state,
        loading: false,
        isUserSettingCreated: false,
      };
    }
    // ----------------------------product list fetch actions-----------------------------
    default:
      return {
        ...state,
      };
  }
};
