import { riskActions } from "../actionTypes";

export const fetchRiskCategoryInitiate = () => {
  return {
    type: riskActions.FETCH_RISKCATEGORY_INITIATE,
  };
};

export const fetchRiskCategorySuccess = (data) => {
  return {
    type: riskActions.FETCH_RISKCATEGORY_SUCCESS,
    payload: data,
  };
};

export const fetchRiskCategoryFail = () => {
  return {
    type: riskActions.FETCH_RISKCATEGORY_FAIL,
  };
};

export const fetchSubRiskCategoryInitiate = (data) => {
  return {
    type: riskActions.FETCH_RISK_SUB_CATEGORY_INITIATE,
    payload: data,
  };
};

export const fetchSubRiskCategorySuccess = (data) => {
  return {
    type: riskActions.FETCH_RISK_SUB_CATEGORY_SUCCESS,
    payload: data,
  };
};

export const fetchSubRiskCategoryFail = () => {
  return {
    type: riskActions.FETCH_RISK_SUB_CATEGORY_FAIL,
  };
};
